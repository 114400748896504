import React from 'react';
import styled from 'styled-components';

interface CharacterCountProps {
  count: number;
  maxLength: number;
  className?: string;
}

const Count = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  display: block;
  text-align: right;
`;

function CharacterCount({ count, maxLength, className }: CharacterCountProps) {
  return (
    <Count className={className}>
      {count} / {maxLength}
    </Count>
  );
}

export { CharacterCount };
