import type { AxiosResponse } from 'axios';

import {
  MakeResponse,
  ModelResponse,
  ISearch,
  ISearchResponse,
  RetrieveSuggestedSectionResponse,
} from 'api/types/searchApiTypes';
import { donedealGateway } from 'api/api-utils/commonApiUtils';
import { API_CLIENT_TIMEOUT } from 'utils';

/*
 * @deprecated - This api is deprecated in favour of the new search filters api
 * https://distilledsch.tpondemand.com/entity/95201-migrate-from-legacy-homepage-filters-to
 * */
async function getAllMakes(): Promise<AxiosResponse<MakeResponse>> {
  const response: AxiosResponse<MakeResponse> = await donedealGateway.get(
    `/ddapi/legacy/search/api/v3/sections/filters/search/cars`,
  );
  return response;
}

/*
 * @deprecated - This api is deprecated in favour of the new search filters api
 * https://distilledsch.tpondemand.com/entity/95201-migrate-from-legacy-homepage-filters-to
 * */
async function getAllModels(
  make: string,
): Promise<AxiosResponse<ModelResponse>> {
  const response: AxiosResponse<ModelResponse> = await donedealGateway.get(
    `/ddapi/legacy/search/api/v3/sections/filters/search/cars/make/${make}/values`,
  );
  return response;
}

async function getMotorCount(
  payload: ISearch,
): Promise<AxiosResponse<ISearchResponse>> {
  const response: AxiosResponse<ISearchResponse> = await donedealGateway.post(
    `/ddapi/legacy/search/api/v4/find/`,
    payload,
    {
      timeout: API_CLIENT_TIMEOUT,
    },
  );
  return response;
}

async function getSuggestedSections(
  title: string,
): Promise<AxiosResponse<RetrieveSuggestedSectionResponse[]>> {
  const response: AxiosResponse<RetrieveSuggestedSectionResponse[]> =
    await donedealGateway.post(`/ddapi/v1/search/suggested/sections`, {
      title,
    });
  return response;
}

export const searchApi = {
  getAllMakes,
  getAllModels,
  getMotorCount,
  getSuggestedSections,
};
