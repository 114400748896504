import StatusCode from 'status-code-enum';

import type {
  IDraftAdResponse,
  ISectionsResponse,
} from 'api/types/adviewApiTypes';
import type { IGetPhotosResponse } from 'api/types/adwriteApiTypes';
import type { IMediaUpload } from 'components/Toolkit/MediaUploader/MediaUploader';
import { adWriteApi } from 'api/adWriteApi';
import { searchApi } from 'api/searchApi';
import { fireToast } from 'helpers/Toasts';
import { countiesNI } from 'constants/counties';
import { CURRENCIES } from 'types';
import type { IBundle } from 'types';
import { AdTypes, AdTypesVariant } from 'components/AdForm/types';

async function getPublishFilters(sectionName: string) {
  try {
    const { data } = await adWriteApi.getPublishFilters(sectionName);
    const { publishFilters } = data;
    return publishFilters;
  } catch (_error) {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  }
}

async function getPublishFiltersNew(sectionName: string) {
  try {
    const { data } = await adWriteApi.getPublishFiltersNew(sectionName);
    return data;
  } catch (_error) {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  }
}

async function getPublishFiltersNewAlternates(sectionName: string) {
  try {
    const { data } = await adWriteApi.getPublishFiltersNewAlternates(
      sectionName,
    );
    return data;
  } catch (_error) {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  }
}

async function getPublishFiltersAlternates(sectionName: string) {
  try {
    const { data } = await adWriteApi.getPublishFiltersAlternates(sectionName);
    const { publishFilters } = data;
    return publishFilters;
  } catch (_error) {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  }
}

async function getSuggestedSections(itemName: string) {
  try {
    const { data } = await searchApi.getSuggestedSections(itemName);
    return data;
  } catch (_error) {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  }
}

async function getPublishFilterValues(
  sectionName: string,
  parentFilterName: string,
  selectionName: string,
) {
  try {
    const { data } = await adWriteApi.getPublishFilterValues(
      sectionName,
      parentFilterName,
      selectionName,
    );
    return data;
  } catch (_error) {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  }
}

function getSectionNameFromSubSection(
  sections: ISectionsResponse,
  draftSection: IDraftAdResponse['section'],
) {
  let sectionName = '';
  sections.map((section) => {
    if (section.showInPlaceAd && section.containsSubsections) {
      section.subSections.map((subsection) => {
        if (subsection.name === draftSection.name && subsection.showInPlaceAd) {
          sectionName = section.name;
        }
        if (subsection.showInPlaceAd && subsection.containsSubsections) {
          subsection.subSections.map((subsubsection) => {
            if (
              subsubsection.name === draftSection.name &&
              subsubsection.showInPlaceAd
            ) {
              sectionName = section.name;
            }
          });
        }
      });
    } else {
      sectionName = section.parentName;
    }
  });
  return sectionName;
}

function formatNCTDate(NCTData: any) {
  const { month, year } = NCTData;
  return `${year}-${month}-01`;
}

function mapAttributes(
  manualInputView: boolean,
  attributes?: { [key: string]: string },
) {
  let mappedAttributes;
  if (attributes) {
    mappedAttributes = Object.keys(attributes).map((attributeKey) => {
      // If the user has manually entered NCT, we need to correct format the response
      if (
        (attributeKey === 'NCTExpiry' && manualInputView) ||
        (attributeKey === 'NCTExpiry' &&
          attributes[attributeKey].hasOwnProperty('year') &&
          attributes[attributeKey].hasOwnProperty('month'))
      ) {
        const expiry = formatNCTDate(attributes[attributeKey]);
        return { name: attributeKey, value: expiry };
      }
      return { name: attributeKey, value: attributes[attributeKey] };
    });
  }
  return mappedAttributes;
}

function getPropertyAccessorFromObjectString(text: string) {
  // 'something[keyName]' => 'keyName'
  let matches = text.match(/\[(.*?)\]/);

  if (matches) {
    return matches[1];
  } else {
    return '';
  }
}

const getSelectedBundle = (bundles?: IBundle[], bundle?: string) => {
  const selected =
    bundles !== undefined && bundles.length !== 0 && bundle
      ? bundles.find(({ id }) => id.toString() === bundle)
      : undefined;
  return selected;
};

const buildMediaUploads: (items: IGetPhotosResponse[]) => IMediaUpload[] = (
  items,
) => {
  let uploads: IMediaUpload[] = [];
  for (let index = 0; index < items.length; index++) {
    uploads.push({
      id: index.toString(),
      error: '',
      success: true,
      lastUpdate: 0,
      ready: true,
      progress: 1,
      localFileId: items[index].id,
      localImage: undefined,
      serverId: items[index].id,
      url: items[index].location,
    });
  }
  return uploads;
};

const getSellerCurrency = (county: string, baseUrl: string) => {
  if (county === '' && baseUrl.indexOf('.co.uk') !== -1) {
    return CURRENCIES.GBP;
  }
  if (countiesNI.includes(county)) {
    return CURRENCIES.GBP;
  } else {
    return CURRENCIES.EUR;
  }
};

/**
 *
 * @param wanted If an ad is wanted or not as a true/false string (required)
 * @param cashOfferOptIn If the ad has opted into a cash offer boolean (optional)
 * @param =
 * @returns an array consisting of one or more ad types eg. ['c2b', 'for-sale']
 */

function buildAdTypesArray(wanted: string, cashOfferOptIn?: boolean) {
  let adTypes: AdTypes[] = [];

  wanted === 'true'
    ? adTypes.push(AdTypesVariant.WANTED)
    : adTypes.push(AdTypesVariant.FOR_SALE);

  if (cashOfferOptIn === true) {
    adTypes.push(AdTypesVariant.C2B);
  }

  return adTypes;
}

export const getOrderIdFromHeaders = (headers: any) => {
  return headers['x-order-id'];
};

const orderIsOk = (status: number): boolean => {
  return status === StatusCode.SuccessCreated;
};

const handleOrderSubmitted = (
  status: number,
  headers: any,
  baseUrl: string,
) => {
  if (orderIsOk(status)) {
    location.assign(`${baseUrl}/order/${getOrderIdFromHeaders(headers)}`);
  }
};

export {
  getPublishFilters,
  getPublishFiltersNew,
  getPublishFiltersAlternates,
  getPublishFiltersNewAlternates,
  getSuggestedSections,
  getSectionNameFromSubSection,
  getPublishFilterValues,
  mapAttributes,
  getPropertyAccessorFromObjectString,
  getSelectedBundle,
  buildMediaUploads,
  getSellerCurrency,
  handleOrderSubmitted,
  buildAdTypesArray,
};
